<template>
    <div>
        <div id="modal-storage-form" class="modal modal-blur fade" tabindex="-1" style="display: none" aria-modal="true"
            role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="action === 'upload'">Upload de arquivo</h5>
                        <h5 class="modal-title" v-else>Nova pasta</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" id="closeFormButton"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col">
                                <div class="mb-4">
                                    <label class="form-label">Caminho para armazenamento
                                    </label>
                                    <input v-model="pathFile" type="text" class="form-control" id="variableKeyInput"
                                        placeholder="Caminho para armazenamento" />
                                </div>
                            </div>
                        </div>
                        <div v-if="action === 'upload'" class="row">
                            <label class="form-label">Selecione o arquivo</label>
                            <div class="col">
                                <v-file-input v-model="file" prepend-icon="ti ti-upload">
                                </v-file-input>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-link link-secondary" data-bs-dismiss="modal" id="cancelButton"
                            @click="cancel()">
                            Cancelar
                        </button>
                        <button class="btn btn-primary ms-auto" data-bs-dismiss="modal" id="saveVariableButton"
                            @click="save()">
                            Criar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VariableForm",
    props: {
        action: {
            type: String,
            required: true,
            default: "upload"
        },
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            actionTitle: "Upload de arquivo",
            pathFile: "",
            file: ""
        }
    },
    mounted() {
        if (this.action === "folder") {
            this.actionTitle = "Nova pasta"
        }

        this.$watch("data", (newData) => {
            this.variable = newData
        })
    },
    methods: {
        cancel() {
            this.$emit("cancel")
        },
        save() {
            if (this.action === "upload") {
                this.$emit("upload", { file: this.file, pathFile: this.pathFile });
                return;
            }
            if (this.action === "folder") {
                this.$emit("newFolder", { pathFile: this.pathFile });
                return;
            }

        }
    }
}
</script>
