<template>
    <div>
        <div class="card">

            <div class="card-body">
                <div v-if="data?.data?.length">
                    <table class="table table-vcenter card-table">
                        <thead>
                            <tr>
                                <th>Nome do arquivo</th>
                                <th>Caminho</th>
                                <th>Tamanho</th>
                                <th> </th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="file in data.data" :key="file.name">
                                <td>
                                    <div class="cursor-pointer" @click="updatePage(file.path)">
                                        <i v-if="file.isFolder" class="ti ti-folder h-10 w-10"></i>
                                        <i v-else class="ti ti-file "></i>
                                        {{ file.name }}
                                    </div>
                                </td>
                                <td @click="CopyPath(file.path)">
                                    <i class="ti ti-copy mr-2 cursor-pointer"></i>
                                    <i class="underline-on-hover cursor-grabbing">
                                        {{ file.path }}
                                    </i>
                                </td>
                                <td>
                                    <div class="">{{ file.size }}</div>
                                </td>
                                <td></td>
                                <td>
                                    <div v-if="!file.isFolder"
                                        class="btn btn-sm h-4  bg-blue border rounded-2 border-light"
                                        @click="emitDownloadFile({ path: file.path, name: file.name })">
                                        <i class="ti ti-download"></i>
                                    </div>
                                    <div v-if="file.isFolder"
                                        class="btn btn-sm h-4 cursor-not-allowed bg-gray-800 border rounded-2 border-light">
                                        <i class="ti ti-download"></i>
                                    </div>
                                    <div class="btn btn-sm h-4 bg-red ml-2 border rounded-2 border-light"
                                        @click="deleteFile(file.path)">
                                        <i class="ti ti-trash"></i>
                                    </div>

                                    <span>&nbsp;</span>
                                    <!-- <span class="dropdown">
                                        <button class="btn btn-sm ti ti-dots-vertical tableOptionsDropdown h-4 m-0"
                                            data-bs-toggle="dropdown">
                                        </button>
                                        <div class="dropdown-menu">
                                            <a :href="'/storage/tables/edit/' +
                                                file.name
                                                " class="dropdown-item editTableButton">
                                                <i class="ti ti-pencil"></i>
                                                Renomear arquivo
                                            </a>
                                            <a href="#" class="dropdown-item " @click="() => { }">
                                                <i class="ti ti-copy"></i>
                                                Duplicar
                                            </a>
                                            <a href="#" class="dropdown-item " @click="() => { }">
                                                <i class="ti ti-download"></i>
                                                Mover arquivo
                                            </a>
                                        </div>
                                    </span> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="!data?.data?.length && !filter.query" class="card-body">
                <div class="empty">
                    <p class="empty-title">Nenhum arquivo encontrado.</p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar seu primeira arquivo!
                    </p>
                    <div class="empty-action">
                        <a href="/storage/upload" id="newTableButton" class="btn btn-primary">
                            <i class="ti ti-plus"></i>
                            Criar um arquivo
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="!data?.data?.length && filter.query" class="card-body">
                <div class="empty bg-muted-lt">
                    <p class="empty-title">
                        Nenhum arquivo encontrado para esta busca.
                    </p>
                </div>
            </div>

            <div class="card-footer">
            </div>
        </div>
    </div>
</template>

<script>

import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "FilesList",
    components: {
        // Pagination
    },
    props: {

        data: {
            type: Object[{}],
            default: []
        }
    },
    emits: ["updateData", "downloadFile"],
    data() {
        return {
            tableList: [],
            filter: {
                query: ""
            },
            dataFileList: [],
            paginationKey: 0,
            showPerPage: 10,
            currentPage: 1,
            total: 0
        }
    },
    async created() {

    },

    async mounted() {
        this.dataFileList = this.dataFile
        this.currentPage = parseInt(this.$route.query.page) || 1
        this.filter.query = this.$route.query.query || ""
        this.filter.group = localStorage.getItem("selectedGroup") || ""

        EventBus.on("update-selected-group", () => {

        })
    },
    methods: {
        async updatePage(path) {
            this.$router.replace({
                query: {
                    path: path
                }
            })
        },

        async emitLoadStorage() {
            this.$emit("updateData", "")

        },

        async emitDownloadFile(data) {
            this.$emit("downloadFile", data)
        },

        async duplicateFile(pathFile) {
            return
        },

        async deleteFile(path) {
            try {
                if (confirm("Deseja realmente excluir este arquivo? Esta ação não poderá ser revertida.")) {
                    await Api.storage.delete(path)
                    EventBus.emit("message", {
                        type: "success",
                        message: "Arquivo excluído com sucesso"
                    })
                    this.dataFileList = await this.emitLoadStorage()
                }
            } catch (err) {
                const error = err.response.data
                if (err.response.status === 400) {
                    EventBus.emit("message", {
                        type: error.type || "danger",
                        message: error.message || "Erro ao excluir arquivo"
                    })
                    return
                }

                EventBus.emit("message", {
                    type: error.type || "danger",
                    message: error.message || "Erro ao excluir arquivo"
                })
            }
        },

        async CopyPath(path) {
            navigator.clipboard.writeText(path)
            EventBus.emit("message", {
                type: "success",
                message: "Caminho copiado para a área de transferência"
            })
        }
    }
}
</script>
<style scoped>
.underline-on-hover:hover {
    text-decoration: underline;
}
</style>
