<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <div class="card">
                            <HeaderButton>
                                <template #default>
                                    <div class="col-auto">
                                        <button id="newArchButton" data-bs-toggle="modal"
                                            data-bs-target="#modal-storage-form" class="btn btn-primary"
                                            @click="storageFormAction = 'folder'">
                                            Nova pasta
                                        </button>
                                    </div>
                                </template>
                                <template #button2>
                                    <button id="newFolderButton" data-bs-toggle="modal"
                                        data-bs-target="#modal-storage-form" class="btn btn-primary"
                                        @click="() => { storageFormAction = 'upload' }">
                                        Upload
                                    </button>
                                </template>
                            </HeaderButton>
                            <SearchBar @search="searchField" />
                        </div>
                        <div class="mt-2">
                            <BreadCrumb :path=path />
                        </div>
                        <FilesList :data=dataFilesList @updateData="updateDataStorage" @downloadFile="downloadFile" />

                    </div>
                </div>
            </div>
            <StorageModal :action="storageFormAction" :data="storageFormData" @upload="sendFile"
                @newFolder="newFolder" />
        </div>
    </div>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb.vue"
import FilesList from "../components/FilesList.vue";
import HeaderButton from "../components/HeaderButton.vue"
import SearchBar from "../components/SearchBar.vue"
import StorageModal from "../components/StorageModal.vue";
import api from "../services/api";

import Api from "../services/api"
import EventBus from "../services/event-bus"
import { store } from "../store"


export default {
    name: "StoragePage",
    components: {
        FilesList,
        BreadCrumb,
        HeaderButton,
        SearchBar,
        StorageModal
    },
    data() {
        return {
            storageFormAction: "upload",
            newArch: "upload",
            storageFormData: null,
            path: [],
            dataFilesList: {}
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
        store.showGroupButton = false
        this.path_param = this.$route.query.path || "/"
        this.$router.replace({
            query: {
                path: this.path_param
            }
        })

        this.dataFilesList = await this.loadStorage();
        this.path = this.breadCrumbData(this.$route.query.path)

    },
    methods: {
        breadCrumbData(path = "") {
            path = path || this.dataFilesList.path;

            // split path for "/"
            let path_arr = path.split("/")

            // tira elementos vazios
            path_arr = path_arr.filter((item) => item !== "")

            if (!path_arr.length) {
                return [{ title: "/", disabled: true, href: "", }]
            }

            let add_path = '?path=';
            const root = {
                title: "HOME",
                disabled: false,
                href: add_path + "/"
            }
            const path_bread_crumb = path_arr.map((item, index) => {
                add_path += item + "/"
                return {
                    title: item,
                    disabled: path_arr.length === index + 1 ? true : false,
                    href: add_path,
                }
            })


            path_bread_crumb.unshift(root)
            return path_bread_crumb
        },

        async updateDataStorage(data) {
            data = typeof data === "object" ? data : null;
            this.dataFilesList = data || await this.loadStorage()
        },

        async loadStorage() {
            const query = this.$route.query
            try {
                const response = await Api.storage.list(query)
                return response.data || [];
            } catch (err) {
                const error = err.response.data
                if (err.response.status === 400) {
                    EventBus.emit("message", {
                        type: error.type || "danger",
                        message: error.message || "Erro ao buscar os arquivos"
                    })
                    return
                }

                EventBus.emit("message", {
                    type: error.type || "danger",
                    message: error.message || "Erro ao buscar os arquivos"
                })
                return [];
            }


        },

        async downloadFile({ path, name }) {
            try {
                const response = await Api.storage.download(path)
                await this.makeFileDownload(response.data, name)

                EventBus.emit("message", {
                    type: "success",
                    message: "Arquivo baixado com sucesso"
                })

            } catch (err) {
                const error = err.response.data
                if (err.response.status === 400) {
                    EventBus.emit("message", {
                        type: error.type || "danger",
                        message: error.message || "Error download file"
                    })
                    return
                }

                EventBus.emit("message", {
                    type: error.type || "danger",
                    message: error.message || "Error download file"
                })
            }
        },
        async makeFileDownload(data, name) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = name;  // Nome do arquivo que será baixado
            document.body.appendChild(a);
            a.click();
            a.remove();
        },

        updateFilter() {
            this.$router.replace({
                query: {
                    page: 1,
                    query: this.filter.query
                }
            })

            this.loadTables()
        },

        async searchField(fileName) {
            try {
                if (fileName === "") {
                    await this.updateDataStorage();
                    return;
                }

                const result = await api.storage.search(fileName)
                await this.updateDataStorage(result.data);
            } catch (err) {
                const error = err.response.data
                if (err.response.status === 400) {
                    EventBus.emit("message", {
                        type: error.type || "danger",
                        message: error.message || "Erro ao buscar arquivo"
                    })
                    return
                }

                EventBus.emit("message", {
                    type: error.type || "danger",
                    message: error.message || "Erro ao buscar arquivo"
                })
            }
        },

        newStorage() {
            this.storageFormAction = "create"
            this.storageFormData = {}
        },

        async sendFile(data) {
            try {
                await Api.storage.upload(data)

                EventBus.emit("message", {
                    type: "success",
                    message: "Arquivo enviado com sucesso"
                })

                this.dataFilesList = await this.loadStorage()
            } catch (err) {
                const error = err.response.data
                if (err.response.status === 400) {
                    EventBus.emit("message", {
                        type: error.type || "danger",
                        message: error.message || "Erro ao enviar arquivo"
                    })
                    return
                }

                EventBus.emit("message", {
                    type: error.type || "danger",
                    message: error.message || "Erro ao enviar arquivo"
                })
            }
        },

        async newFolder(data) {
            try {
                await Api.storage.newFolder(data);
                await this.updateDataStorage()
            } catch (err) {
                const error = err.response.data
                if (err.response.status === 400) {
                    EventBus.emit("message", {
                        type: error.type || "danger",
                        message: error.message || "Erro ao criar pasta"
                    })
                    return
                }

                EventBus.emit("message", {
                    type: error.type || "danger",
                    message: error.message || "Erro ao criar pasta"
                })
            }
        },
    }
}
</script>